import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Drawer } from 'antd'
import { Spinner, Button } from 'react-bootstrap'

import PickupDetails from './PickupDetails'
import SendSMS from '../../Tickets/partials/SendSMS'
import VerifyDeliveryModal from '../../Deliveries/partials/VerifyDeliveryModal'
import VoidActions from '../../../components/VoidActions'

import { getDelivery } from '../../Deliveries/services'
import { handleError } from '../../../helpers'

export default function PickupDrawer({ drawerId, closeDrawer, reload }) {
   const [pickup, setPickup] = useState(null)   
   const [showVerifyModal, setShowVerifyModal] = useState(false)

   useEffect(()=>{
      !pickup && getDelivery(drawerId, {
         include: 'store,user,authorizations,attachments'
      })
         .then(response => setPickup(response.data.data))
         .catch(error => handleError(error))
   }, [pickup, drawerId])

   return (
      <Drawer
         title="Detalles de Entrega"
         placement="right"
         onClose={() => {
            setPickup(null)
            closeDrawer()
         }}
         open={drawerId!==null}
         width={450}
      >
         {pickup ? (
            <React.Fragment>
               <PickupDetails pickup={pickup} />
               { !pickup.verified_at && !pickup.voided_at && (
                  <div className="mb-10">
                     <Button 
                        variant="outline-primary" 
                        onClick={() => setShowVerifyModal(true)}
                     >
                        Verificar Entrega
                     </Button>
                  </div>
               )}
               <Link to={`/pickups/print/${drawerId}`} target="_blank">
                  <Button variant="outline-primary" className="mr-10">Imprimir</Button>
               </Link>

               <SendSMS user={pickup.user} reload={()=>{}} />
               
               <VoidActions 
                  delivery={pickup} 
                  reload={() => {
                     setPickup(null)
                     reload()
                  }} 
               />

               { showVerifyModal && (
                  <VerifyDeliveryModal
                     visible
                     deliveryId={pickup.id}
                     onClose={() => setShowVerifyModal(false)}
                     reload={() => setPickup(null)}
                  />
               )}
            </React.Fragment>
         ) : (
            <Spinner animation="grow" />
         )}         
      </Drawer>
   )
}