import asisfarmaApi from '../../services/asisfarmaApi'

export const getDeliveries = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/deliveries',{params}).then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getDelivery = (id,params) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/deliveries/${id}`, {params})
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createDelivery = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/deliveries', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateDelivery = (id,data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .put(`/deliveries/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getDeliveryCompanies = () => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/companies', {
            params: { type: 'delivery' }
         }).then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getDeliveryAddresses = user_id => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/addresses', {
            params: { 'filter[user_id]': user_id }
         }).then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createUserAddress = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/addresses', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
   
)

export const notifyDelivery = (id, data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post(`/deliveries/${id}/notify`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)