import React, {useState, useEffect} from 'react'
import { Form, Spinner, InputGroup, Button } from 'react-bootstrap'
import { Card, Switch } from 'antd'

import TicketDrawer from './partials/TicketDrawer'
import TicketTable from './partials/TicketsTable'
import TicketExport from './partials/TicketExport'

import {getTickets} from './services'
import { handleError, parsePagination } from '../../helpers'

export default function TicketsList() {
   const [drawerId, setDrawerId] = useState(null)
   const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
   const [sort, setSort] = useState("-created_at")
   const [tickets, setTickets] = useState(null)
   const [filterType, setFilterType] = useState('user.document')
	const [filterValue, setFilterValue] = useState('')
   const [onlyPendding, setOnlyPendding] = useState(false)

   useEffect(()=>{
      !tickets && getTickets({
         ...pagination,
         [`filter[${filterType}]`]: filterValue,
         'filter[state]': onlyPendding ? 'pendding' : '',
         sort,
         include: 'user,ticketType'
      })
         .then(res => {
            setTickets(res.data.data)
            setPagination(parsePagination(res))
         })
         .catch(error => handleError(error))
   }, [tickets, pagination, filterType, filterValue, sort, onlyPendding])

   return (
      <React.Fragment>
         <div className="mb-20">
            <TicketExport />
         </div>
         <div className="text-right filters">
            <InputGroup>
               <InputGroup.Prepend>
                  <Form.Control 
                     as="select" 
                     className="bg-light"
                     value={filterType}
                     onChange={e => setFilterType(e.target.value)}
                  >
                     <option value="user.document">Buscar por cédula de cliente</option>
                     <option value="message">Buscar por texto en mensaje</option>
                  </Form.Control>
               </InputGroup.Prepend>
               <Form.Control 
                  placeholder="Escriba aquí" 
                  value={filterValue}
                  onChange={e => setFilterValue(e.target.value)}
               />
               <InputGroup.Append>
                  <Button color="primary" onClick={()=>setTickets(null)}>Buscar</Button>
               </InputGroup.Append>
            </InputGroup>
         </div>
         <div className="mb-15">
            <Switch
               checked={onlyPendding}
               onChange={()=>{
                  setOnlyPendding(!onlyPendding)
                  setTickets(null)
               }}
               className="mr-5"
            />
            Ver solo tickets pendientes
         </div>
         <Card title="Listado de Tickets">
            { tickets ? (
               <TicketTable 
                  setDrawerId={setDrawerId}
                  tickets={tickets}
                  pagination={pagination}
                  setPagination={setPagination}
                  reload={()=>setTickets(null)}
                  sort={sort}
                  setSort={setSort}
               />
            ) : (
               <Spinner animation="grow" />
            )}
         </Card>
         { drawerId && <TicketDrawer 
            drawerId={drawerId} 
            closeDrawer={()=>setDrawerId(null)}
         /> }
      </React.Fragment>
   )
}