import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Form, Spinner, InputGroup, Row, Col } from 'react-bootstrap'
import { message, Modal } from 'antd'

import AuthorizationDetailsForm from './AuthorizationDetailsForm'

import { getSettings } from '../../Settings/services'
import { storeAuthorization, syncData } from '../services'
import { handleError } from '../../../helpers'
import UpdateUserDocumentModal from '../../Users/partials/UpdateUserDocumentModal'

export default function NewAuthorizationModal({ visible, onClose, delivery, reload }) {
	const { user } = useSelector(state => state.auth)
	const [options, setOptions] = useState(null)
	const [allowManualSync, setAllowManualSync] = useState("0")
	const [records, setRecords] = useState(null)
	const [loadingSync, setLoadingSync] = useState(false)
	const { handleSubmit, register, errors, watch } = useForm({})
	const [showEditDocumentModal, setShowEditDocumentModal] = useState(false)

	useEffect(() => {
		!options && getSettings({ 'filter[key]': 'delivery_types,allow_manual_sync' })
			.then(res => {
				setOptions(res.data.data.filter(setting => setting.key === 'delivery_types'))
				setAllowManualSync(res.data.data.filter(setting => setting.key === 'allow_manual_sync')[0].value)
			})
			.catch(handleError)
	}, [ options ])
	
	const onSubmit = async values => {
		try {
			await records.forEach(async record => {
				try {
					await storeAuthorization({
						delivery_id: delivery.id,
						responsable_name: user.name,
						authorization_key: values.authorization_key,
						authorization_days: values.authorization_days,
						type: values.type,
						plu: values[`plu_${record.key}`],
						pv: values[`pv_${record.key}`],
						quantity_requested: values[`quantity_requested_${record.key}`],
						quantity_delivered: values[`quantity_delivered_${record.key}`],
						medicine_name: values[`medicine_name_${record.key}`],
						medicine_key: values[`medicine_key_${record.key}`],
						synced_at: record.synced_at,
					})
				} catch (error) {
					message.error(`No se pudo almacenar autorización ${values.authorization_key}`)
				}
			});
		} catch (error) {
			message.error('Algo salio mal. Favor contactar soporte@smart4.com.co')
		}

		message.success('Autorización vinculada exitosamente.')
		reload()
		onClose()
	}

	const onSyncData = () => {
		let authorization_key = watch('authorization_key')
		
		if(!authorization_key) 
			return message.warning('Debe ingresar un número de autorización primero')

		setLoadingSync(true)

		syncData({ authorization_key })
			.then(res => {
				message.info(res.data.message)
				setRecords(res.data.data)
				setLoadingSync(false)
			})
			.catch(error => {
				handleError(error)
				setLoadingSync(false)
			})
	}
	
	return (
		<React.Fragment>
			<Modal open={visible} onCancel={onClose} footer={false}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label>Número de Autorización <span className='text-danger'>*</span></Form.Label>
						<InputGroup>
							<Form.Control 
								type="number"
								name="authorization_key"
								ref={register({ required:true })}
								placeholder="Escriba aquí unicamente números"
								disabled={records}
							/>
							{ records ? (
								<Button variant="outline-primary" onClick={() => setRecords(null)} size="sm">
									Editar
								</Button>
							) : (
								<Button variant='outline-primary' size="sm" onClick={onSyncData} disabled={loadingSync}>
									Buscar datos{" "}
									{ loadingSync && <Spinner animation="border" size="sm" /> }
								</Button>
							)}
						</InputGroup>
						{ errors.authorization_key && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
					</Form.Group>
					{ records && (
						<React.Fragment>
							{ records.length > 0 ? (
								<React.Fragment>
									<Row className="mt-10">
										<Form.Group as={Col}>
											<Form.Label>Tiempo de Autorizacion (dias) <span className='text-danger'>*</span></Form.Label>
											<Form.Control 
												type="number"
												name="authorization_days"
												ref={register({ required:true })}
											/>
											{ errors.authorization_days && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>Tipo de Entrega <span className='text-danger'>*</span></Form.Label>
											<Form.Control
												as="select"
												name="type"
												ref={register({ required: true })}
											>
												<option value="">:: Seleccione una opción ::</option>
												{ options && options.map(opt => (
													<option key={opt.id} value={opt.value}>{opt.value}</option>
												)) }
											</Form.Control>
											{ errors.type && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
										</Form.Group>
									</Row>
									<Row>
										<Form.Group as={Col}>
											<Form.Label>Cédula en Autorización</Form.Label>
											<Form.Control value={records[0].patient_document["0"]} disabled />
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>Cédula del Solicitante</Form.Label>
											<Form.Control value={delivery.user.document} disabled />
											<Form.Text className='text-primary' onClick={() => setShowEditDocumentModal(true)}>
												Modificar cédula del sistema
											</Form.Text>
										</Form.Group>
									</Row>
									{ records.map(record => (
										<AuthorizationDetailsForm 
											key={record.key}
											register={register}
											errors={errors}
											data={record}
											allowManualSync={allowManualSync}
											options={options}
										/>
									)) }
								</React.Fragment>
							) : <em>No se encontraron registros</em>}
						</React.Fragment>
					)}
					<hr />
					<Button type="submit" disabled={!records}>Agregar Autorización</Button>
				</Form>
			</Modal>
			{ showEditDocumentModal && (
				<UpdateUserDocumentModal 
					visible
					onClose={() => setShowEditDocumentModal(false)}
					user={delivery.user}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}