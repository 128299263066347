import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import DeliveryPrint from './DeliveryPrint'
import DeliveriesList from './DeliveriesList'

export default function Deliveries() {
   return (
      <Switch>
         <Route exact path="/deliveries/print/:id" render={props => <DeliveryPrint {...props} />} />
         
         <AppLayout title="Entregas & Domicilios">
            <Route exact path="/deliveries" component={DeliveriesList} />
         </AppLayout>

         <Redirect to="/404" />
      </Switch>
   )
}