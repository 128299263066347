import React from 'react'
import { Descriptions } from 'antd'
import Moment from 'react-moment';
import 'moment/locale/es';
import { getCurrentStatus } from './TicketsTable';
import moment from 'moment';

const {Item} = Descriptions

export default function TicketDetails({ ticket }) {
   return (
      <React.Fragment>         
         <Descriptions 
            title={`Ticket #${ticket.id}`} 
            column={1} 
            className="mb-30"
            bordered 
            size="small"
         >
            <Item label="Número de ticket" >#{ticket.id}</Item>
            <Item label="Fecha de creado">
               <Moment format="D MMMM YYYY h:mma">
                  {ticket.created_at}
               </Moment>
            </Item>
            <Item label="Fecha de actualizado">
               <Moment format="D MMMM YYYY h:mma">
                  {ticket.updated_at}
               </Moment>
            </Item>
            <Item label="Estado Actual" >{ getCurrentStatus(ticket.state).label }</Item>
            

            <Item label="Tipo" >{ticket.type.name}</Item>
            <Item label="Mensaje" >{ticket.message}</Item>
            { ticket.responses.map(response => (
               <Item key={response.id} label={(
                  <React.Fragment>
                     <small>{response.user.name}</small>
                     <p>{moment(response.created_at).format('YYYY-MM-DD HH:mm')}</p>
                  </React.Fragment>
               )}>
                  <p>{response.message}</p>
               </Item>
            )) }
         </Descriptions>         
         <Descriptions 
            title="Detalles del Usuario" 
            column={1} 
            className="mb-30"
            bordered 
            size="small"
         >
            <Item label="Nombre del Paciente">{ticket.user.name}</Item>
            <Item label="Documento del Paciente">{ticket.user.document}</Item>
            <Item label="Celular del Paciente">{ticket.user.mobile}</Item>
            <Item label="Correo del Paciente">{ticket.user.email}</Item>
         </Descriptions>
      </React.Fragment>
   )
}