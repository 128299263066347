import asisfarmaApi from '../../services/asisfarmaApi'

export const getUsers = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/users',{params}).then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getUser = (id,params) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/users/${id}`, {params})
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createUser = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/users', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateUser = (id,data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .put(`/users/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)