import React from 'react'
import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'

import DeliveryLimitTab from './partials/DeliveryLimitTab'
import DeliveryTypesTab from './partials/DeliveryTypesTab'
import GeneralSettingsTab from './partials/GeneralSettingsTab'

export default function SettingsHomePage(props) {
	const selectedTab = props.match.params.tab
	const history = useHistory()

	const items = [
		{
			key: 'delivery_types',
			label: 'Tipos de Entregas',
			children: <DeliveryTypesTab />
		},
		{
			key: 'delivery_limit',
			label: 'Límite de Entregas',
			children: <DeliveryLimitTab />
		},
		{
			key: 'general_settings',
			label: 'Ajustes Generales',
			children: <GeneralSettingsTab />
		}
	]

	return (
		<AppLayout title="Ajustes del Sistema">
			<Tabs 
				items={items} 
				tabPosition="top" 
				activeKey={selectedTab} 
				size="middle"
				type="card"
				onChange={newKey => history.push(`/settings/${newKey}`)}
			/>
		</AppLayout>
	)
}

