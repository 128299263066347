import asisfarmaApi from '../../services/asisfarmaApi'

export const login = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/auth/login', data)
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)

export const logout = () => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/auth/logout')
            .then(response => resolve(response))
            .catch(error => reject(error));
   })
)