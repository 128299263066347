import React, {useState} from 'react'
import { Modal, Descriptions, message } from 'antd'
import { Button, Form } from 'react-bootstrap'

import { sendSms } from '../services'
import { handleError } from '../../../helpers'

export default function SendSMS({ user, ticketId, reload }) {
	const [open, setOpen] = useState(false)
	const [sms, setSms] = useState('')

	const handleSubmit = () => {		
		sendSms({ mobile: user.mobile, message: sms, ticketId })
			.then(() => {
				message.success('Mensaje enviado exitosamente')
				reload()
				setSms('')
				setOpen(false)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Button variant="outline-primary" onClick={() => setOpen(true)}>Enviar SMS al Paciente</Button>
			<Modal
				cancelText="Cancelar"
				okText="Enviar SMS"
				onOk={handleSubmit}
				title="Enviar SMS a Paciente"
				open={open}
				onCancel={() => setOpen(false)}
			>
				<Descriptions column={1}>
					<Descriptions.Item label="Nombre del Paciente">{user && user.name}</Descriptions.Item>
            	<Descriptions.Item label="Documento del Paciente">{user && user.document}</Descriptions.Item>
            	<Descriptions.Item label="Celular del Paciente">{user && user.mobile}</Descriptions.Item>
				</Descriptions>
				{ user && user.mobile.toString().length === 10 ? (
					<Form.Group className="mt-20">
						<Form.Label>Mensaje (Máx 320 carácteres)</Form.Label>
						<Form.Control
							as="textarea"
							value={sms}
							onChange={e => setSms(e.target.value)}
						/>
						<Form.Text className="text-warning">Un mismo celular no puede recibir más de 1 mensaje cada 30 min.</Form.Text>
						<Form.Text className={sms.length >= 320 ? 'text-danger' : 'text-success'}>{sms.length} de 320 carácteres permitidos</Form.Text>
					</Form.Group>
				) : (
					<p>No se encontro un celular válido para SMS del paciente.</p>
				)}
			</Modal>
		</React.Fragment>
	)
}