import React, { useState,useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Helmet } from "react-helmet"

import AuthorizationDetails from './partials/AuthorizationDetails'
import { getDelivery } from './services'
import { handleError } from '../../helpers'

export default function DeliveryPrint(props) {
   const [delivery, setDelivery] = useState(null)   

   useEffect(()=>{
      !delivery && getDelivery(props.match.params.id, {
         include: 'address,user,attachments,authorizations'
      })
         .then(response => setDelivery(response.data.data))
         .catch(error => handleError(error))
   }, [delivery, props])

   if(!delivery) return <Spinner animation="grow" />
   else setTimeout(() => window.print(), 1500)

   return (
      <React.Fragment>
         <Helmet>
            <title>Entrega</title>
         </Helmet>
         <div className="p-30">
            <AuthorizationDetails delivery={delivery} />
            <div className="float-right mt-30">
               <img alt="instafarma logo" src="/images/logo-horizontal.png" width="190px" />
            </div>
         </div>
      </React.Fragment>
   )
}