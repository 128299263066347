import React from 'react'
import { Descriptions } from 'antd'
import Moment from 'react-moment'
import { Button } from 'react-bootstrap'
import 'moment/locale/es'

const {Item} = Descriptions

export default function PickupDetails({ pickup }) {
   return (
      <React.Fragment>
         <Descriptions 
            title={`Detalles de Entrega #${pickup.id}`} 
            column={1}
            className="mb-30"
            bordered
            size="small"
         >
            <Item label="Consecutivo entrega" >#{pickup.id}</Item>
            <Item label="Tipo de entrega">Recoger en Farmacia</Item>
            <Item label="Entrega programada el">
               <Moment format="D MMMM YYYY">
                  {pickup.schedule_date}
               </Moment>               
            </Item>
            {/* <Item label="Franja de entrega programada">{pickup.schedule_timerange}</Item> */}
            <Item label="Farmacia Seleccionada">{pickup.store.name}</Item>
            <Item label="Dirección de Farmacia">{pickup.store.complete_address}</Item>
            <Item label="Estado de Entrega">
               { pickup.status.label }
            </Item>
            <Item label="Fecha de Verificación">
               {pickup.verified_at ? pickup.verified_at : <em>Sin verificar</em>}
            </Item>
         </Descriptions>
         <Descriptions
            title="Archivos Adjuntos"
            column={1}
            className="mb-30"
            bordered
            size="small"
         >
            { pickup.attachments.map(attachment => (
               <Item key={attachment.id} label={`Adjunto ID ${attachment.id}`}>
                  <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                     <Button variant="outline-primary">Ver Adjunto</Button>
                  </a>
               </Item>
            )) }
         </Descriptions>
         { pickup.authorizations.map(authorization => (
            <Descriptions
               key={authorization.id}
               title={`Autorización ${authorization.authorization_key}`} 
               column={1}
               className="mb-30"
               bordered
               size="small"
            >
               <Item label="PLU">{authorization.plu}</Item>
               <Item label="PV">{authorization.pv}</Item>
               <Item label="Medicamentos">{authorization.medicines_names}</Item>
               <Item label="Tipo Entrega">{authorization.type}</Item>
               <Item label="Días Autorización">{authorization.authorization_days}</Item>
               <Item label="Responsable">{authorization.responsable_name}</Item>
               <Item label="Cantidad Solicitada">{authorization.quantity_requested}</Item>
               <Item label="Cantidad Entregada">{authorization.quantity_delivered}</Item>
               <Item label="Sincronizada el">
                  {authorization.synced_at ? authorization.synced_at : 'Sin sincronizar'}
               </Item>
            </Descriptions>
         ))}
      </React.Fragment>
   )
}
