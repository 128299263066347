import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default function UserMenu(props) {
   const [showMenu, setShowMenu] = useState(false)
   const companyName = useSelector(state => state.auth.user.name)
   const userName = useSelector(state => state.auth.user.email)

   const handleShowMenu = () => {
      setShowMenu(!showMenu)
   }

   return (
      <React.Fragment>
         <TopMenuContainer onClick={()=>setShowMenu(!showMenu)}>
            <h6 className="inline mr-10 regular">{companyName}</h6>
            <img src="/images/avatar.png" width="50px" alt="instafarma avatar" />
         </TopMenuContainer>
         <BkgOverlay onClick={handleShowMenu} show={showMenu}>
            <DropdownContainer show={showMenu}>
               <MenuIntroDiv>
                  {userName}
               </MenuIntroDiv>
               <Link to="/auth/logout">
                  <MenuMegaLink>Cerrar Sesión</MenuMegaLink>
               </Link>
            </DropdownContainer>
         </BkgOverlay>
      </React.Fragment>
   )
}

const TopMenuContainer = styled.div`
   &:hover {
      cursor: pointer;        
   }
`;

const BkgOverlay = styled.div`
   display: ${props => !props.show && "none"};
   position: fixed;
   top: 64px;
   left: 0px;
   z-index: 100;
   width: 100%;
   height: 100%;
   background-color: #00000010
`;

const DropdownContainer = styled.div`
   display: ${props => !props.show && "none"};
   transition: visibility 0s, opacity 0.5s linear;   
   z-index: 10;
   background-color: #FFF;   
   position: fixed;
   right: 30px;
   top: 64px;
   -webkit-box-shadow: 0px 0px 15px 0px #ddd;
   -moz-box-shadow: 0px 0px 15px 0px #ddd;
   box-shadow: 0px 0px 15px 0px #ddd;
   border-bottom-right-radius: 15px;
   border-bottom-left-radius: 15px;
   text-align: left;
   border: none;
`;

const MenuIntroDiv = styled.div`
   background-color: #010053;
   color: #fff;
   padding: 20px 20px 10px;
   font-weight: bolder;
   font-size: 1.2rem;
`;

const MenuMegaLink = styled.div`
   width: 280px;
   padding: 15px 20px;
   border-bottom: 1px solid #eee;
   color: #0000aa;
   &:hover {
      cursor: pointer;        
   }
   @media(max-width: 386px) {
      width: 100%;
   }
`;