import React from 'react'
import { Card } from 'react-bootstrap'
import { DatePicker } from 'antd'
import moment from 'moment'
import { Button } from 'react-bootstrap'

const { RangePicker } = DatePicker

export default function TopBarFilters({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	reload
}) {
	return (
		<Card>
			<div className="p-10 text-right">
				<RangePicker
					ranges={{
						'Hoy': [moment(), moment()],
						'Esta semana': [moment().startOf('week'), moment().endOf('week')],
						'Este mes': [moment().startOf('month'), moment().endOf('month')],
						'Mes pasado': [moment().subtract(1,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
						'Este año': [moment().startOf('year'), moment().endOf('year')],
					}}
					placeholder={["F. de Inicio", "F. Final"]}
					value={[startDate, endDate]}
					className="mr-3"
					onChange={ values => { 
						if(values.length > 0){
							setStartDate(values[0]); setEndDate(values[1]) 
						}
					}}					
				/>
				<Button color="primary" onClick={reload}>Generar Reporte</Button>
			</div>
		</Card>
	)
}