import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import TicketsList from './TicketsList'

export default function Tickets() {
   return (
      <AppLayout title="Tickets de Soporte">
         <Switch>
            <Route exact path="/tickets" component={TicketsList} />

            <Redirect to="/404" />
         </Switch>
      </AppLayout>      
   )
}