import React, { useState,useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Helmet } from "react-helmet"

import PickupDetails from './partials/PickupDetails'

import { getDelivery } from '../Deliveries/services'
import { handleError } from '../../helpers'

export default function PickupPrint(props) {
   const [pickup, setPickup] = useState(null)   

   useEffect(()=>{
      !pickup && getDelivery(props.match.params.id, {
         include: 'address,user,store,attachments,authorizations'
      })
         .then(response => setPickup(response.data.data))
         .catch(error => handleError(error))
   }, [pickup, props])

   if(!pickup) return <Spinner animation="grow" />
   else setTimeout(() => window.print(), 1500)

   return (
      <React.Fragment>
         <Helmet>
            <title>Entrega</title>
         </Helmet>
         <div className="p-30">
            <PickupDetails pickup={pickup} />
            <div className="float-right mt-30">
               <img alt="instafarma logo" src="/images/logo-horizontal.png" width="190px" />
            </div>
         </div>
      </React.Fragment>
   )
}