import React, {useState, useEffect} from 'react'
import { Form, Spinner, InputGroup, Button } from 'react-bootstrap'
import { Card, Select } from 'antd'

import PickupDrawer from './partials/PickupDrawer'
import PickupTable from './partials/PickupsTable'
import DeliveriesExport from '../Deliveries/partials/DeliveriesExport'

import { getStores } from './services'
import { getDeliveries } from '../Deliveries/services'
import { handleError, parsePagination } from '../../helpers'

export default function PickupsList() {
   const [drawerId, setDrawerId] = useState(null)
   const [pagination, setPagination] = useState({page: 1, per_page: 10, total: 0})
   const [sort, setSort] = useState("-schedule_date")
   const [pickups, setPickups] = useState(null)
   const [filterType, setFilterType] = useState('authorizations.authorization_key')
   const [filterValue, setFilterValue] = useState('')
   const [stores, setStores] = useState(null)
   const [selectedStores, setSelectedStores] = useState([])

   useEffect(()=>{
      !stores && getStores()
         .then(res => {
            setSelectedStores(res.data.data.map(store => store.id))
            setStores(res.data.data)
         })
         .catch(error => handleError(error))
   }, [stores])

   useEffect(()=>{
      !pickups && stores && getDeliveries({
         ...pagination,
         [`filter[${filterType}]`]: filterValue,
         'filter[store_id]': selectedStores.join(','),
         [`filter[pickups]`]: 1,
         sort,
         include: 'user,store,authorizations'
      })
         .then(res => {
            setPickups(res.data.data)
            setPagination(parsePagination(res))
         })
         .catch(error => handleError(error))
   }, [pickups, pagination, filterType, filterValue, sort, stores, selectedStores])

   return (
      <React.Fragment>
         <div className="mb-20">
            <DeliveriesExport scope="pickups" />
         </div>
         <div className="text-right filters">
            <InputGroup>
               <InputGroup.Prepend>
                  <Form.Control 
                     as="select" 
                     className="bg-light"
                     value={filterType}
                     onChange={e => setFilterType(e.target.value)}
                  >
                     <option value="authorizations.authorization_key">Buscar por número de autorización</option>
                     <option value="schedule_date">Buscar por fecha de agendamiento (YYYY-MM-DD)</option>
                  </Form.Control>
               </InputGroup.Prepend>
               <Form.Control 
                  placeholder="Escriba aquí" 
                  value={filterValue}
                  onChange={e => setFilterValue(e.target.value)}
               />
               <InputGroup.Append>
                  <Button color="primary" onClick={()=>setPickups(null)}>Buscar</Button>
               </InputGroup.Append>
            </InputGroup>
         </div>
         <div className="mb-20">
            <Select
               mode="multiple"
               allowClear
               style={{ width: '100%' }}
               placeholder="Please select"
               value={selectedStores}
               onChange={values => {
                  setSelectedStores(values)
                  setPickups(null)
               }}
            >
               { stores && stores.map(store => (
                  <Select.Option key={store.id} value={store.id}>{store.name}</Select.Option>
               )) }
            </Select>
         </div>
         <Card title="Listado de Entregas en Farmacia">
            { pickups ? (
               <PickupTable 
                  setDrawerId={setDrawerId}
                  pickups={pickups}
                  pagination={pagination}
                  setPagination={setPagination}
                  reload={()=>setPickups(null)}
                  sort={sort}
                  setSort={setSort}
               />
            ) : (
               <Spinner animation="grow" />
            )}
         </Card>
         { drawerId && <PickupDrawer 
            drawerId={drawerId} 
            closeDrawer={()=>setDrawerId(null)}
            reload={()=>setPickups(null)}
         /> }
      </React.Fragment>
   )
}