import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import './theme.css'
import './helpers.css'

import App from './App'

ReactDOM.render((
   <HashRouter>
      <Provider store={store}>     
         <PersistGate loading="Cargando..." persistor={persistor}>
            <App />
         </PersistGate>
      </Provider>
   </HashRouter>
), document.getElementById('root'))