import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'

import AuthLayout from '../../layouts/AuthLayout'
import { deliveryConfirm } from './services'
import { message } from 'antd'
import { handleError } from '../../helpers'

export default function ConfirmForm() {
	const { register, errors, handleSubmit, reset } = useForm()

	const onSubmit = values => {
		deliveryConfirm(values)
			.then(res => {
				message.success(res.data.message)
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<AuthLayout>
			<h3 className="text-center mb-20">Confirmar Entrega</h3>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Cédula Domiciliario</Form.Label>
					<Form.Control 
						name="admin_document"
						ref={register({ required: true })}
						type="number"
						placeholder="Escriba aquí..."
					/>
					{ errors.admin_document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Cédula del Paciente</Form.Label>
					<Form.Control 
						name="document"
						ref={register({ required: true })}
						type="number"
						placeholder="Escriba aquí..."
					/>
					{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Pin de Confirmación</Form.Label>
					<Form.Control 
						name="confirmation_pin"
						ref={register({ required: true })}
						type="number"
						placeholder="Escriba aquí..."
					/>
					{ errors.confirmation_pin && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Button color="primary" type="submit">Verificar Pin</Button>
			</Form>
		</AuthLayout>
	)
}