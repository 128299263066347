import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AppLayout from '../../layouts/AppLayout'

import GeneralReport from './GeneralReport'

export default function Reports() {
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/reports" to="/reports/general" />

				<AppLayout title="Reporte General">
					<Route exact path="/reports/general" component={GeneralReport} />
				</AppLayout>

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}