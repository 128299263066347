import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ConfirmForm from './ConfirmForm'

export default function DeliveryConfirm() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/confirmar" component={ConfirmForm} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}