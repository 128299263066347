import React from 'react'

import { Pagination as AntdPagination } from 'antd'

export default function Pagination({ pagination, reload, setPagination }) {

   const handleChange = (page, size) => {
      setPagination({ ...pagination, page, per_page: size })
      reload()
   }

   return (
      <AntdPagination
         className="mt-20 mb-20 text-right"
         total={pagination.total}
         showLessItems={true}
         pageSize={pagination.per_page}
         current={pagination.current_page}
         onChange={handleChange}
			disabled={pagination.total === 0}
      />
   )
}