import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function AuthorizationDetailsForm({ register, errors, data, allowManualSync }) {
	return (
		<React.Fragment>
			<hr />
			<Row>
				<Form.Group as={Col}>
					<Form.Label>PLU <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						name={`plu_${data.key}`}
						ref={register({ required:true })}
						defaultValue={data.plu ? data.plu["0"] : ''}
						disabled={allowManualSync === "0"}
					/>
					{ errors[`plu_${data.key}`] && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>PV <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						name={`pv_${data.key}`}
						ref={register({ required:true })}
						defaultValue={data.pv ? data.pv["0"] : ''}
						disabled={allowManualSync === "0"}
					/>
					{ errors[`pv_${data.key}`] && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Cantidad Solicitada <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						name={`quantity_requested_${data.key}`}
						ref={register({ required:true })}
						defaultValue={data.quantity_requested}
						disabled={allowManualSync === "0"}
					/>
					{ errors[`quantity_requested_${data.key}`] && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Cantidad Entregada <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						name={`quantity_delivered_${data.key}`}
						ref={register({ required:true })}
						defaultValue={data.quantity_delivered}
					/>
					{ errors[`quantity_delivered_${data.key}`] && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Medicamentos <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					name={`medicine_name_${data.key}`}
					ref={register({ required:true })}
					defaultValue={data.medicine_name ? data.medicine_name["0"] : ''}
					disabled={allowManualSync === "0"}
				/>
				<Form.Control 
					name={`medicine_key_${data.key}`}
					ref={register({ })}
					defaultValue={data.medicine_key ? data.medicine_key["0"] : ''}
					disabled
					style={{ display:'none' }}
				/>
				{ errors[`medicine_name_${data.key}`] && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}