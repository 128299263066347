import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import PickupPrint from './PickupPrint'
import PickupsList from './PickupsList'

export default function Pickups() {
   return (
      <Switch>
         <Route exact path="/pickups/print/:id" render={props => <PickupPrint {...props} />} />
         
         <AppLayout title="Entregas en Farmacia">
            <Route exact path="/pickups" component={PickupsList} />
         </AppLayout>

         <Redirect to="/404" />
      </Switch>
   )
}