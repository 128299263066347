import React, { useState, useEffect } from 'react'
import { DatePicker, message, Modal } from 'antd'
import moment from 'moment'
import 'moment/locale/es'
import locale from 'antd/lib/locale/es_ES'

import { updateDelivery } from '../services'
import { handleError } from '../../../helpers'

export default function EditDeliveryModal({ visible, onClose, delivery, reload }) {
	const [newDate, setNewDate] = useState(null)

	useEffect(() => {
		visible && setNewDate(moment(delivery.schedule_date))
	}, [ visible, delivery ])

	const onSubmit = () => {
		updateDelivery(delivery.id, {
			schedule_date: newDate.format('YYYY-MM-DD')
		})
			.then(() => {
				message.success('Fecha actualizada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleClose = () => {
		setNewDate(null)
		onClose()
	}
	
	return (
		<Modal 
			open={visible}
			onCancel={handleClose}
			onOk={onSubmit}
			title="Reprogramar Entrega" 
			okText="Reprogramar"
			cancelText="Cancelar"
		>
			<p className="bold mb-2">Seleccione la nueva fecha</p>
			<DatePicker 
				value={newDate}
				onChange={setNewDate}
				className="full-width"
				locale={locale}
				disabledDate={current => current && current < moment().endOf('day')}
			/>
		</Modal>
	)
}