import React, {useState, useEffect} from 'react'
import { Form, Spinner, InputGroup, Button } from 'react-bootstrap'
import { Card } from 'antd'

import DeliveryDrawer from './partials/DeliveryDrawer'
import DeliveryTable from './partials/DeliveriesTable'
import DeliveriesExport from './partials/DeliveriesExport'

import {getDeliveries} from './services'
import { handleError, parsePagination } from '../../helpers';

export default function DeliveriesList() {
   const [drawerId, setDrawerId] = useState(null)
   const [pagination, setPagination] = useState({page: 1, per_page: 10, total: 0})
   const [sort, setSort] = useState("-schedule_date")
   const [deliveries, setDeliveries] = useState(null)
   const [filterType, setFilterType] = useState('authorizations.authorization_key')
   const [filterValue, setFilterValue] = useState('')
   const [reload, setReload] = useState(true)

   useEffect(()=>{
      reload && getDeliveries({
         ...pagination,
         [`filter[${filterType}]`]: filterValue,
         [`filter[deliveries]`]: 1,
         include: 'user,authorizations',
         sort,
      })
         .then(res => {
            setReload(false)
            setDeliveries(res.data.data)
            setPagination(parsePagination(res))
         })
         .catch(error => {
            setReload(false)
            handleError(error)
         })
   }, [reload, pagination, filterType, filterValue, sort])

   return (
      <React.Fragment>
         <div className="mb-20">
            <DeliveriesExport scope="deliveries" />
         </div>
         <div className="text-right filters">
            <InputGroup>
               <InputGroup.Prepend>
                  <Form.Control 
                     as="select" 
                     className="bg-light"
                     value={filterType}
                     onChange={e => setFilterType(e.target.value)}
                  >
                     <option value="authorizations..authorization_key">Buscar por número de autorización</option>
                     <option value="schedule_date">Buscar por fecha de agendamiento (YYYY-MM-DD)</option>
                     <option value="user.document">Buscar por cedula de paciente</option>
                  </Form.Control>
               </InputGroup.Prepend>
               <Form.Control 
                  placeholder="Escriba aquí" 
                  value={filterValue}
                  onChange={e => setFilterValue(e.target.value)}
               />
               <InputGroup.Append>
                  <Button color="primary" onClick={()=>setReload(true)}>Buscar</Button>
               </InputGroup.Append>
            </InputGroup>
         </div>
         <Card title="Listado de Domicilios">
            { deliveries ? (
               <DeliveryTable 
                  setDrawerId={setDrawerId}
                  deliveries={deliveries}
                  pagination={pagination}
                  setPagination={setPagination}
                  reload={()=>setReload(true)}
                  sort={sort}
                  setSort={setSort}
               />
            ) : (
               <Spinner animation="grow" />
            )}
         </Card>
         { drawerId && <DeliveryDrawer 
            drawerId={drawerId} 
            closeDrawer={()=>setDrawerId(null)}
            reload={()=>setReload(true)}
         /> }
      </React.Fragment>
   )
}