import React from 'react'
import styled from 'styled-components'

const FullWidthContainer = styled.div`
   background-color: #e2f0ff;
   min-height: 100vh;
   padding-top: 50px;
`;

const ContentContainer = styled.div`
   background-color: #fff;
   padding: 40px 50px 50px;
   border-radius: 15px;
   width: 100%;
   max-width: 450px;
   margin: auto;
`;

export default function AuthLayout({ children }) {
   return (
      <FullWidthContainer>
         <div className="text-center mb-20">
            <img 
               src="/images/logo.png" 
               alt="Instafarma Logo" 
               width="150px" 
               className="mb-0"
            />
            <p className="text-primary bold mb-30">
               <em>Aplicativo IPS</em>
            </p>
         </div>
         <ContentContainer>            
            {children}
         </ContentContainer>
      </FullWidthContainer>
   )
}