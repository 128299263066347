import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Popconfirm, message } from 'antd'
import moment from 'moment'

import { updateDelivery } from '../containers/Deliveries/services'
import { handleError } from '../helpers'

export default function VoidActions({ delivery, reload }) {
	const [voidReason, setVoidReason] = useState('')
	const [loading, setLoading] = useState(false)

   const handleVoid = () => {
		if(!voidReason)
			return message.warning('Debe indicar una razón de cancelación.')

		setLoading(true)
      updateDelivery(delivery.id, {
			voided_at: moment().format('YYYY-MM-DD'),
			void_reason: voidReason
		})
         .then(() => {
            message.success('Entrega anulada exitosamente.')
				setLoading(false)
            reload()
         })
         .catch(error => handleError(error, setLoading))
   }

	if(delivery.voided_at)
		return (<p className='text-warning mt-10'><em>Esta entrega fue anulada</em></p>)

	return (
		<React.Fragment>
			<div className="mt-15">
				<Popconfirm
					title={(
						<React.Fragment>
							<p>¿Confirma la cancelación de la entrega?</p>
							<Form.Control 
								as="textarea"
								value={voidReason}
								onChange={e => setVoidReason(e.target.value)}
								placeholder="Escriba la razón de cancelación."
							/>
						</React.Fragment>
					)}
					okText="Confirmar"
					cancelText="Cancelar"
					onConfirm={()=>handleVoid()}
				>
					<Button variant="outline-danger" disabled={loading}>
						Cancelar Entrega
					</Button>
				</Popconfirm>
			</div>
		</React.Fragment>
	)
}