import React from 'react'
import { Descriptions } from 'antd'
import Moment from 'react-moment'
import 'moment/locale/es'
import { Button } from 'react-bootstrap'

const {Item} = Descriptions

export default function AuthorizationDetails({ delivery, verifyDelivery }) {
   return (
      <React.Fragment>
         <Descriptions 
            title={`Detalles de Entrega #${delivery.id}`} 
            column={1}
            className="mb-30"
            bordered
            size="small"
         >
            <Item label="Consecutivo entrega" >#{delivery.id}</Item>
            <Item label="Tipo de entrega">Domicilio</Item>
            <Item label="Entrega programada el">
               <Moment format="D MMMM YYYY">
                  {delivery.schedule_date}
               </Moment>               
            </Item>
            {/* <Item label="Franja de entrega programada">{delivery.schedule_timerange}</Item> */}
            <Item label="Dirección de Entrega">{delivery.complete_address}</Item>
            <Item label="Teléfono Domicilio">{delivery.address.address_phone ? delivery.address.address_phone : 'Sin registro'}</Item>
            <Item label="Valor del domicilio">{delivery.price_terms}</Item>
            <Item label="Estado de Entrega">
               { delivery.delivered_at ? "Finalizada" : "Pendiente de Entrega" }
            </Item>
            <Item label="Fecha de Verificación">
               {delivery.verified_at ? delivery.verified_at : <em>Sin verificar</em>}
            </Item>
         </Descriptions>
         <Descriptions
            title="Archivos Adjuntos"
            column={1}
            className="mb-30"
            bordered
            size="small"
         >
            { delivery.attachments.map(attachment => (
               <Item key={attachment.id} label={`Adjunto ID ${attachment.id}`}>
                  <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                     <Button variant="outline-primary">Ver Adjunto</Button>
                  </a>
               </Item>
            )) }
         </Descriptions>
         { delivery.authorizations.map(authorization => (
            <Descriptions
               key={authorization.id}
               title={`Autorización ${authorization.authorization_key}`} 
               column={1}
               className="mb-30"
               bordered
               size="small"
            >
               <Item label="PLU">{authorization.plu}</Item>
               <Item label="PV">{authorization.pv}</Item>
               <Item label="Medicamentos">{authorization.medicines_names}</Item>
               <Item label="Tipo Entrega">{authorization.type}</Item>
               <Item label="Días Autorización">{authorization.authorization_days}</Item>
               <Item label="Responsable">{authorization.responsable_name}</Item>
               <Item label="Cantidad Solicitada">{authorization.quantity_requested}</Item>
               <Item label="Cantidad Entregada">{authorization.quantity_delivered}</Item>
               <Item label="Sincronizada el">
                  {authorization.synced_at ? authorization.synced_at : 'Sin sincronizar'}
               </Item>
            </Descriptions>
         ))}
         <Descriptions
            title={`Detalles del Paciente`} 
            column={1}
            className="mb-30"
            bordered
            size="small"
         >
            <Item label="Paciente">{delivery.user.name}</Item>
            <Item label="Cedula">{delivery.user.document}</Item>
            <Item label="Celular">{delivery.user.mobile}</Item>
            <Item label="Teléfono">{delivery.user.phone}</Item>
            <Item label="Correo">{delivery.user.email}</Item>
            <Item label="Tipo">{delivery.user.parent_id ? 'Beneficiario' : 'Titular'}</Item>
         </Descriptions>
      </React.Fragment>
   )
}
