import React, { useState, useEffect } from 'react'
import { Card, Empty, message, Switch } from 'antd'
import { Form, Spinner } from 'react-bootstrap'

import { getSettings, updateSetting } from '../services'
import { handleError } from '../../../helpers'
import { useSelector } from 'react-redux'

export default function GeneralSettingsTab() {
	const { user } = useSelector(state => state.auth)
	const [currentSettings, setCurrentSettings] = useState(null)
	const [loading, setLoading] = useState(false)
	const allowedEmails = ['info@smart4.com.co','tecnologia@asisfarma.com.co']

	useEffect(() => {
		!currentSettings && getSettings({
			'filter[key]': 'allow_manual_sync'
		})
			.then(res => setCurrentSettings(res.data.data))
			.catch(handleError)
	}, [ currentSettings ])

	const onAllowManualSyncChange = () => {
		if(!allowedEmails.includes(user.email))
			return message.warning('No tiene permisos para modificar este ajuste')

		setLoading(true)
		updateSetting(currentSettings[0].id, { value: currentSettings[0].value === '1' ? 0 : 1 })
			.then(res => {
				message.success(res.data.message)
				setCurrentSettings(null)
			})
			.catch(handleError)
			.finally(() => setLoading(false))
	}

	return (
		<Card>
			{ currentSettings ? (
				<React.Fragment>
					{ currentSettings.length > 0 ? (
						<Form>
							<Form.Group>
								<Form.Label>Modo contingencia (servicio de sincronización de autorizaciones) <span className='text-danger'>*</span></Form.Label>
								<div>
									<Switch 
										checked={currentSettings[0].value === '1'}
										onChange={onAllowManualSyncChange}
										disabled={loading}
									/>{" "}
									{ currentSettings[0].value === '1' ? 'Activado' : 'Desactivado' }
								</div>
							</Form.Group>
						</Form>
					) : (
						<Empty description="No existe limite definido. Favor contactar a soporte." />
					)}
				</React.Fragment>
			) : <Spinner animation="border" />}
		</Card>
	)
}