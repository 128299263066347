import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Spinner } from 'react-bootstrap'
import { Statistic, Table } from 'antd'
import moment from 'moment'

import TopBarFilters from './partials/TopBarFilters'
import { getGeneralReport } from './services'
import { handleError } from '../../helpers'

export default function GeneralReport() {
	const [report, setReport] = useState(null)
	const [startDate, setStartDate] = useState(moment().startOf('month'))
	const [endDate, setEndDate] = useState(moment().endOf('month'))

	useEffect(()=>{
		!report && getGeneralReport({
			from: moment(startDate).format('YYYY-MM-DD'),
			to: moment(endDate).format('YYYY-MM-DD'),
		})
			.then(res => setReport(res.data))
			.catch(error => handleError(error))
	}, [report, startDate, endDate])	

	return (
		<React.Fragment>
			<TopBarFilters
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				reload={()=>setReport(null)}
			/>
			{ report ? (

				<Row className="mt-10">
					<Col sm="6" md="2" className="mb-10">
						<Card className="p-10">
							<Statistic title="Pacientes en el Sistema" value={report.users_total} />
						</Card>
					</Col>
					<Col sm="6" md="2" className="mb-10">
						<Card className="p-10">
							<Statistic title="Pacientes con Acceso" value={report.users_active} />
						</Card>
					</Col>
					<Col sm="6" md="2" className="mb-10">
						<Card className="p-10">
							<Statistic title="Total de Autorizaciones" value={report.authorizations_total} />
						</Card>
					</Col>
					<Col sm="6" md="3" className="mb-10">
						<Card className="p-10">
							<Statistic title="Total de Domicilios Creados" value={report.deliveries_total} />
						</Card>
					</Col>
					<Col sm="6" md="3" className="mb-10">
						<Card className="p-10">
							<Statistic title="Entregas en Farmacia Creadas" value={report.pickups_total} />
						</Card>
					</Col>
					<Col sm="12" md="4" className="mb-10">
						<Card>
							<Card.Body>
								<Card.Title>Autorizaciones por Estado</Card.Title>
								<Table 
									rowKey={ (record, i) => i } 
									columns={[
										{ title:'Estado', dataIndex: 'name' }, 
										{ title: 'Total', dataIndex: 'total' }, 
										{ title: '%', dataIndex: 'rate' }
									]} 
									size="small"
									pagination={false}
									dataSource={report.authorizations_states}
								/>
							</Card.Body>
						</Card>
					</Col>
					<Col sm="12" md="4" className="mb-10">
						<Card>
							<Card.Body>
								<Card.Title>Domicilios por Estado</Card.Title>
								<Table 
									rowKey={ (record, i) => i } 
									columns={[
										{ title:'Estado', dataIndex: 'name' }, 
										{ title: 'Total', dataIndex: 'total' }, 
										{ title: '%', dataIndex: 'rate' }
									]} 
									size="small"
									pagination={false}
									dataSource={report.deliveries_states}
								/>
							</Card.Body>
						</Card>
					</Col>
					<Col sm="12" md="4" className="mb-10">
						<Card>
							<Card.Body>
								<Card.Title>Entregas en Farmacia por Estado</Card.Title>
								<Table 
									rowKey={ (record, i) => i } 
									columns={[
										{ title:'Estado', dataIndex: 'name' }, 
										{ title: 'Total', dataIndex: 'total' }, 
										{ title: '%', dataIndex: 'rate' }
									]} 
									size="small"
									pagination={false}
									dataSource={report.pickups_states}
								/>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			) : (
				<div className="text-center mt-30">
					<Spinner animation="grow" />
				</div>
			)}
		</React.Fragment>
	)
}