import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Login from './Login'
import Logout from './Logout'

export default function Auth() {
   return (
      <Switch>
         <Redirect exact from="/auth" to="/auth/login" />
         
         <Route exact path="/auth/login" component={Login} />         
         <Route exact path="/auth/logout" component={Logout} />
         
         <Redirect to="/404" />
      </Switch>
   )   
}