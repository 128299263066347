import asisfarmaApi from '../../services/asisfarmaApi'

export const getSettings = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/settings',{params}).then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getSetting = (id,params) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/settings/${id}`, {params})
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const storeSetting = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/settings', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateSetting = (id,data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .put(`/settings/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteSetting = (id) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .delete(`/settings/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)