import React, { useState } from 'react'
import { Modal, message } from 'antd'
import { Form } from 'react-bootstrap'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function UpdateUserDocumentModal({ visible, onClose, user, reload }) {
	const [document, setDocument] = useState(user.document)

	const handleSubmit = () => {
		let values = { document }
		if(values.document === user.document) delete values.document

		updateUser(user.id, values)
			.then(() => {
				message.success('Usuario actualizado exitosamente')
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal
			open={true}
			onCancel={onClose}
			onOk={handleSubmit}
			okText="Actualizar Usuario"
			cancelText="Cancelar"
			title="Cédula del Usuario"
		>
			<Form.Group>
				<Form.Label>Cédula de Ciudadania</Form.Label>
				<Form.Control
					value={document}
					onChange={e => setDocument(e.target.value)}
				/>
				<Form.Text>Tenga presente que esto modificará todo el registro histórico del usuario y ahora el usuario tendrá que iniciar sesión con la nueva cédula que usted ingrese.</Form.Text>
			</Form.Group>
		</Modal>
	)
}