import asisfarmaApi from '../../services/asisfarmaApi'

export const getTickets = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/tickets', {params})
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getTicket = (id,params) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/tickets/${id}`, {params})
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateTicket = (id, data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .put(`/tickets/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })   
)

export const sendSms = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/tickets/sendsms', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)